<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="8" offset-md="2">
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label for="full_name" class="font-weight-700 font-size-16"
                >Full name</label
              >
              <v-text-field
                id="full_name"
                v-model.trim="emergencyContact.name"
                dense
                filled
                label="Full name"
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label for="mobile_number" class="font-weight-700 font-size-16"
                >Phone number</label
              >
              <PhoneTextField
                id="mobile_number"
                v-model="emergencyContact.mobile"
                :value="emergencyContact.mobile"
              >
              </PhoneTextField>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Email</label>
              <v-text-field
                v-model.trim="emergencyContact.email"
                :rules="[
                  validateRules.validEmail(emergencyContact.email, 'Email'),
                  validateRules.minLength(emergencyContact.email, 'Email', 1),
                  validateRules.maxLength(emergencyContact.email, 'Email', 255),
                ]"
                dense
                filled
                label="Email"
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Relation</label>
              <v-text-field
                v-model.trim="emergencyContact.relation"
                :rules="[
                  validateRules.minLength(
                    emergencyContact.relation,
                    'Relation',
                    1
                  ),
                  validateRules.maxLength(
                    emergencyContact.relation,
                    'Relation',
                    255
                  ),
                ]"
                dense
                filled
                label="Relation"
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Unit no.</label>
              <v-text-field
                v-model.trim="emergencyContact.unit_no"
                :rules="[
                  validateRules.minLength(
                    emergencyContact.unit_no,
                    'Unit no.',
                    1
                  ),
                  validateRules.maxLength(
                    emergencyContact.unit_no,
                    'Unit no.',
                    255
                  ),
                ]"
                dense
                filled
                label="Unit no."
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Address Line 1</label>
              <v-text-field
                v-model.trim="emergencyContact.street_1"
                :rules="[
                  validateRules.minLength(
                    emergencyContact.street_1,
                    'Address Line 1',
                    1
                  ),
                  validateRules.maxLength(
                    emergencyContact.street_1,
                    'Address Line 1',
                    255
                  ),
                ]"
                dense
                filled
                label="Address Line 1"
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Address Line 2</label>
              <v-text-field
                v-model.trim="emergencyContact.street_2"
                :rules="[
                  validateRules.minLength(
                    emergencyContact.street_2,
                    'Address Line 2',
                    1
                  ),
                  validateRules.maxLength(
                    emergencyContact.street_2,
                    'Address Line 2',
                    255
                  ),
                ]"
                dense
                filled
                label="Address Line 2"
                solo
                flat
              ></v-text-field>
            </template>
            <template v-if="updateEngineer && pageLoading">
              <v-skeleton-loader
                class="custom-skeleton my-2 custom-text-height-40px"
                type="text"
              >
              </v-skeleton-loader>
            </template>
            <template v-else>
              <label class="font-weight-700 font-size-16">Postal Code</label>
              <v-text-field
                v-model.trim="emergencyContact.postal_code"
                :rules="[
                  validateRules.minLength(
                    emergencyContact.postal_code,
                    'Postal Code',
                    1
                  ),
                  validateRules.maxLength(
                    emergencyContact.postal_code,
                    'Postal Code',
                    255
                  ),
                ]"
                dense
                filled
                v-mask="'######'"
                label="Postal Code"
                solo
                flat
              ></v-text-field>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

export default {
  name: "emergency-contact",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      emergencyContact: {
        name: null,
        mobile: null,
        relation: null,
        unit_no: null,
        street_1: null,
        street_2: null,
        postal_code: null,
        email: null,
      },
      fieldDescriptions: [],
    };
  },
  watch: {
    emergencyContact: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          EngineerEventBus.$emit("update:emergency-contact", param);
        }, _this.timeoutLimit);
      },
    },
    engineer() {
      this.setEmergencyContact();
    },
  },
  methods: {
    setEmergencyContact() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.emergencyContact.name = _this.engineer.emergency_contact_name;
        _this.emergencyContact.mobile = _this.engineer.emergency_contact_number;
        _this.emergencyContact.unit_no =
          _this.engineer.emergency_contact_unit_no;
        _this.emergencyContact.street_1 =
          _this.engineer.emergency_contact_street_1;
        _this.emergencyContact.street_2 =
          _this.engineer.emergency_contact_street_2;
        _this.emergencyContact.postal_code =
          _this.engineer.emergency_contact_postal_code;
        _this.emergencyContact.relation =
          _this.engineer.emergency_contact_relation;
        _this.emergencyContact.email = _this.engineer.emergency_contact_email;
      }
    },
  },
  components: {
    PhoneTextField,
  },
  mounted() {
    this.setEmergencyContact();
  },
};
</script>
